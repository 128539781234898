var render = function () {
  var _vm$option$componentO;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "frmBlock__layout"
  }, [_c('input', {
    staticClass: "frmBlock__textField",
    attrs: {
      "type": "text",
      "placeholder": "Text를 입력해주세요.",
      "maxlength": (_vm$option$componentO = _vm.option.componentOption.properties[0].elements) === null || _vm$option$componentO === void 0 ? void 0 : _vm$option$componentO.textMaxLength
    },
    domProps: {
      "value": _vm.text
    },
    on: {
      "input": function input($event) {
        return _vm.updateOption($event);
      }
    }
  })]), _c('div', {
    staticClass: "frmBlock__count"
  }, [_c('em', [_vm._v(_vm._s(_vm.text.length))]), _vm._v(" /" + _vm._s(_vm.displayLength) + " ")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }