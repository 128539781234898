<template>
  <div>
    <div class="frmBlock__layout">
      <input
        type="text"
        class="frmBlock__textField"
        placeholder="Text를 입력해주세요."
        :value="text"
        :maxlength="
          option.componentOption.properties[0].elements?.textMaxLength
        "
        @input="updateOption($event)"
      />
    </div>
    <div class="frmBlock__count">
      <em>{{ text.length }}</em>
      /{{ displayLength }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    option: {
      type: Object
    }
  },
  data() {
    return {
      text: ""
    };
  },
  computed: {
    initialValue() {
      return this.$route.path === "/option"
        ? this.option.componentOption.properties[0].initialValue
        : this.option.value.text;
    },
    displayLength() {
      return this.option.componentOption.properties[0].elements?.textMaxLength >
        255
        ? 255
        : this.option.componentOption.properties[0].elements?.textMaxLength;
    }
  },
  mounted() {
    this.text = this.initialValue;
    if (this.$route.path === "/option") {
      this.updateProperty(this.initialValue);
    }
  },
  methods: {
    // 프로퍼티 업데이트 (파트너)
    updateProperty(value) {
      let modified = {
        id: this.option.componentOption.id,
        properties: [
          {
            id: this.option.componentOption.properties[0].id,
            initialValue: value
          }
        ]
      };
      this.$emit("updateProperty", this.option.componentOption.key, modified);
    },
    // 옵션 Update
    updateOption(e) {
      this.text = e.target.value;
      this.$emit(
        "updateOption",
        this.option.componentOption.key,
        this.option.componentOption.properties[0].key,
        e.target.value
      );
      if (this.$route.path === "/option") {
        this.updateProperty(e.target.value);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
